<template>
  <div class="top-area-container">
    <div class="top-area">
      <div class="top-area-right top-last">
        <div class="check-box-row">
          <div v-if="!mniAccount" class="top-export-icon" @click="openCloseFilters">
            <span class="intip4 tb-export" data-tool-tip="Open Filter Options">
              <font-awesome-icon :icon="['fal', 'cog']" />
              <span v-if="appliedFilterCount > 0" class="filter-cnt-bubble filter-cnt-badge">{{
                appliedFilterCount > 0 ? `${appliedFilterCount}` : ''
              }}</span>
            </span>
          </div>
          <div>
            <b-radio-select-checkbox-for-events
              v-if="!isEventLoading"
              icon-pos="left"
              :options="eventOptions"
              title=""
              header="Event"
              class="events-dd"
              :event-loading-for-mni="eventLoadingForMni"
              :disable-drop-down-select-option="true"
              :enable-option-click-select="true"
              :apply-white-labeling="true"
              @dropdown-closed="onEventDropdownClose"
            />
          </div>
          <div v-if="mniAccount">
            <b-select-checkbox
              header="Lookback Window (days)"
              :options="daysToconvertOptions"
              icon-pos="left"
              class="events-dd"
              :multi-select-enabled="false"
              :apply-white-labeling="true"
              :disable-tool-tip="true"
              @dropdown-closed="applyModifiedFilters"
              @selected-options="onSelectDayToConvert"
            />
          </div>
          <div
            v-if="mniAccount"
            class="ftop-icons fltr-btn tt"
            :class="{
              disabled: disableAdvancedFilter,
            }"
            @click="toggleFilterModal"
          >
            <div class="ftop-icon">
              <font-awesome-icon :icon="['far', 'filter']" class="fa-regular fa-filter btn-icon" />
            </div>
            <div v-if="appliedFilterCount > 0" class="applied-cnt">
              <span class="acnum">{{ appliedFilterCount }}</span>
            </div>
            <div class="ttip-wrap"><div class="ttip">Advanced Filters</div></div>
          </div>
          <div class="icon-cal">
            <b-datepicker
              :week1="[dates.startDate, dates.endDate]"
              :week2="[dates.compareStartDate, dates.compareEndDate]"
              :campaign-options="ioOptions"
              @change="bDatepickerChanges"
            />
            <div
              v-if="hideReportBuilderPopup"
              class="top-export-icon downloadIcon"
              @click="toggleShowExportTable(!showExportTable)"
            >
              <span class="intip4 tb-export" data-tool-tip="Open Report Builder">
                <font-awesome-icon :icon="['fal', 'arrow-to-bottom']" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <b-modal
      v-if="showExportTable"
      hide-close-button
      enable-click-out-side
      @close="toggleShowExportTable(false)"
    >
      <export-table slot="modal-body" @close="toggleShowExportTable(false)" />
    </b-modal> -->
    <!-- <div v-if="showAdvanceFilters" class="advance-filter-container">
      <AdvancedFiltersModal
        :is-ad-group-loading="isAdGroupLoading"
        :on-select-ad-groups="onSelectAdGroups"
        :on-select-i-o="onSelectIO"
        :on-select-media-types="onSelectMediaTypes"
        :set-filter-options="setFilterOptions"
        :filters="filters"
        @cancel="applyModifiedFilters"
      />
    </div> -->
    <transition name="expand-table">
      <div v-if="showFilters" class="filter-panel-wrap" @click="onclickFilterSec">
        <div v-if="loadingFilters">
          <b-loading-spinner />
        </div>
        <div v-else>
          <div class="filter media-settings">
            <div class="filter-ttl">
              <span>MEDIA</span>
            </div>
            <div class="filter-group mediaFilters">
              <div>
                <b-select-checkbox
                  :options="filters.ioOptions || ioOptions"
                  title=""
                  header="Campaign"
                  class="media-select"
                  component-id="ioOptionDropdown"
                  :show-all="filters.ioOptions.length > 1 || ioOptions.length > 1"
                  @selected-options="onSelectIO"
                  @dropdown-closed="campaignDropdownClosed"
                />
              </div>
              <b-select-checkbox
                :options="filters.adGroupOptions || adGroupOptions"
                title=""
                header="Ad Group"
                :disabled="isAdGroupLoading"
                class="ad-group-select"
                :show-all="filters.adGroupOptions.length > 1 || adGroupOptions.length > 1"
                @selected-options="onSelectAdGroups"
                @dropdown-closed="adGroupDropdownClosed"
              />
              <div class="fp-text-link view-pop-fltr" :class="{ disabled: isAdGroupLoading }">
                <p @click="toggleAdvanceFilterModal">Advanced Filters</p>
                <span
                  >{{ appliedFilterCount > 0 ? `(${appliedFilterCount} Applied)` : '' }}
                  <font-awesome-icon
                    v-if="appliedFilterCount > 0 && !isAdGroupLoading"
                    :icon="['far', 'times']"
                    class="advance-filter-clear-icon"
                    @click="onClearAdvanceFilters"
                  />
                </span>
              </div>
            </div>
          </div>
          <div class="filter method-settings">
            <div class="filter-ttl"><span>ATTRIBUTION SETTINGS</span></div>
            <div :class="`filter-group ${isVCRCategory ? 'readonly-db' : ''}`">
              <b-radio-tab-group
                :initial-group="filters.totalOrUniqueRadioOptions || totalOrUniqueRadioOptions"
                class="tot-uniq"
                @update="updateTotalOrUniqueRadioOptions"
              />
            </div>
            <div class="filter-ttl"><span>Methodology</span></div>
            <div :class="`filter-group ${isVCRCategory ? 'readonly-db' : ''}`">
              <b-select
                class="media-select"
                :options="filters.methodologyOptions"
                :selected="filters.methodology"
                @change="onSelectMethodology"
              />
              <div class="conv-content">
                <div class="filter-ttl"><span>Conversion Window</span></div>
                <div class="conversion-opt-cont">
                  <div class="opt-b1" data-children-count="1">
                    <input
                      v-model="filters.conversionWindowDisplay"
                      type="text"
                      name="conversion-window"
                      class="flp-input input-conv"
                      @keypress="onlyNumber"
                    />
                  </div>
                  <div class="opt-b2">
                    <b-checkbox
                      label="Days"
                      name="Days"
                      icon="circle"
                      :is-radio="true"
                      :checked="filters.conversionWindowUnit === 'DAYS'"
                      @change="updateConversionWindowUnit"
                    />
                    <b-checkbox
                      label="Hours"
                      name="Hours"
                      icon="circle"
                      :is-radio="true"
                      :checked="filters.conversionWindowUnit === 'HOURS'"
                      @change="updateConversionWindowUnit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <k-button
            :size="3"
            label="APPLY"
            :class="{ disabled: isAdGroupLoading }"
            @click="applyFilters"
          />
          <font-awesome-icon
            :icon="['far', 'times']"
            class="close-filters"
            @click="openCloseFilters"
          />
        </div>
      </div>
    </transition>
    <div v-if="showAdvanceFilters" class="advance-filter-container">
      <AdvancedFiltersModal
        :is-ad-group-loading="isAdGroupLoading"
        :on-select-ad-groups="onSelectAdGroups"
        :on-select-i-o="onSelectIO"
        :on-select-media-types="onSelectMediaTypes"
        :set-filter-options="setFilterOptions"
        :filters="filters"
        @cancel="applyModifiedFilters"
      />
    </div>
    <div v-if="showEventChangePopup.visible" class="show-event-change-popup">
      <EventConfirmModal
        :description="showEventChangePopup.description"
        @cancel="onCloseEventChangePopup"
        @change="onChangeEventChangePopup"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { isEqual } from 'lodash';
import { isBlank } from 'adready-api/helpers/common';
import planApi from 'adready-api/api/plans';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import store from '@/store';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import fetchPublishersMixin from '@/components/mixins/fetch-publishers-mixin';
import fetchIosMethodMixin from '~/components/mixins/fetch-ios-method-mixin';
import advertiserReportsAPI from '~/api/advertiser-reports';
import gaEventsMixin from '~/components/mixins/ga-events-mixin';
import fetchEventsMethodMixin from '~/components/mixins/fetch-events-method-mixin';
import campaignMixin from '~/components/mixins/campaign-mixin';
import { buildProcessedEvent } from '~/helpers/global/event-helpers';
import authzMixin from '~/components/mixins/authz-mixin';
import fetchMediaTypesMethodMixin from '~/components/mixins/fetch-mediatypes-method-mixin';
import filterMixin from '~/components/mixins/filter-mixins';

import {
  DATEPICKER_RANGE_OPTIONS,
  DATEPICKER_COMPARE_RANGE_OPTIONS,
  DEFAULT_CONVERSION_WINDOW_UNIT,
  DEFAULT_CONVERSION_WINDOW_DISPLAY,
  DEFAULT_METHODOLOGY,
  MNI_ACCOUNT_ID,
  MNI_DEMO_ACCOUNT_ID,
  MAX_DAYS_TO_CONVERT,
  ENUM_IS_WATCH_STATS,
  LAYOUT_TYPES,
  WINDOW_TYPE_INCREMENTALITY,
  WINDOW_TYPE_HALO_EFFECT,
  WINDOW_TYPE_BRAND_LIFT,
} from '~/constant';
import config from '~/config';
import {
  convertEpochToNYTimezone,
  isDemoInstance,
  generateFlipDefaultDates,
} from '~/util/utility-functions';
// import BDropdown from '~/components/elements/b-dropdown.vue';
import { buildQueryString, getCachedFilters, setCacheFilters } from '~/helpers/global/url-helpers';
import AdvancedFiltersModal from '~/components/dash-panels/advanceFilters/advanced-filters-modal.vue';
import BLoadingSpinner from './b-loading-spinner.vue';
import BRadioSelectCheckboxForEvents from './b-radio-select-checkbox-for-events.vue';
import EventConfirmModal from '../../pages/EventConfirmModal.vue';

export default {
  components: {
    KButton: () => import(/* webpackChunkName: "k-button" */ '~/components/elements/k-button.vue'),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
    BRadioTabGroup: () =>
      import(
        /* webpackChunkName: "b-radio-tab-group" */ '~/components/elements/b-radio-tab-group.vue'
      ),
    BCheckbox: () =>
      import(/* webpackChunkName: "b-checkbox" */ '~/components/elements/b-checkbox.vue'),
    // BRadioSelectCheckbox: () =>
    //   import(
    //     /* webpackChunkName: "b-radio-select-checkbox" */ '~/components/elements/b-radio-select-checkbox.vue'
    //   ),
    BRadioSelectCheckboxForEvents,
    BDatepicker: () =>
      import(/* webpackChunkName: "b-datepicker" */ '~/components/elements/b-datepicker.vue'),
    // ExportTable: () =>
    //   import(
    //     /* webpackChunkName: "dash-export-table" */ '~/components/dash-panels/export/table.vue'
    //   ),
    BSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-select-checkbox" */ '~/components/elements/b-select-checkbox.vue'
      ),
    // BDropdown,
    // BModal: () => import(/* webpackChunkName: "b-modal" */ '~/components/elements/b-modal.vue'),
    AdvancedFiltersModal,
    EventConfirmModal,
    FontAwesomeIcon,
    BLoadingSpinner,
  },
  mixins: [
    fetchEventsMethodMixin,
    fetchMediaTypesMethodMixin,
    gaEventsMixin,
    campaignMixin,
    authzMixin,
    filterMixin,
    fetchIosMethodMixin,
    fetchPublishersMixin,
  ],
  props: {
    hideReportBuilderPopup: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAllEventsPerformanceSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdvanceFilterInOverview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const filterOptionsArray = [
      'ioOptions',
      'audienceOptions',
      'creativeOptions',
      'adGroupOptions',
      'mediaTypeOptions',
      'campaignOptions',
      'keywordOptions',
      'publisherOptions',
    ];
    return {
      mniAccount: false,
      // mniEventOptions: [],
      isAdGroupLoading: false,
      loadingFilters: false,
      filters: this.genFilters(),
      initialFilters: JSON.parse(JSON.stringify(this.genFilters(true))),
      timeoutId: null,
      daysToconvertOptions: MAX_DAYS_TO_CONVERT,
      filtersModified: false,
      disableAdvancedFilter: false,
      showAdvanceFilters: false,
      eventLoadingForMni: false,
      filterOptionsArray,
      showEventChangePopup: { visible: false, payload: {} },
    };
  },
  computed: {
    appliedFilterCount: get('common/appliedFilterCount'),
    showFilters: get('common/showFilters'),
    isMniAccount: get('common/isMniAccount'),
    ioOptions: get('dashboard/filters@ioOptions'),
    mediaTypeOptions: get('dashboard/filters@mediaTypeOptions'),
    // Generate filter options are getting generated from dashboard/filters.js
    // filters: get('dashboard/filters'),
    adGroupOptions: get('dashboard/filters@adGroupOptions'),
    eventOptions: get('dashboard/filters@eventOptions'),
    metricOptions: get('dashboard/filters@eventOptions'),
    totalOrUniqueRadioOptions: get('dashboard/filters@totalOrUniqueRadioOptions'),
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    selectedEvent: get('dashboard/GET_SELECTED_EVENT'),
    dates: get('dashboard/dates'),
    isEventLoading: get('common/isEventLoading'),
    showExportTable: get('common/showExportTable'),
    selectOneCampaignType: get('common/selectOneCampaignType'),
    selectedMediaTypes: get('dashboard/GET_SELECTED_MEDIATYPES'),
    selectedPublishers: get('dashboard/GET_SELECTED_PUBLISHERS'),
    selectedCreatives: get('dashboard/GET_SELECTED_CREATIVES'),
    selectedAudiences: get('dashboard/GET_SELECTED_AUDIENCES'),
    selectedIOs: get('dashboard/GET_SELECTED_IOS'),
    universalPixelId: get('common/universalPixelId'),
    appliedFilterCountNew() {
      let appliedCount = 0;
      const filterUsed = this.filters;

      const filterKeys = [
        'ioOptions',
        'adGroupOptions',
        'mediaTypeOptions',
        'audienceOptions',
        'creativeOptions',
        'publisherOptions',
      ];

      filterKeys.forEach((key) => {
        if (filterUsed[key]?.some((v) => v.checked)) {
          appliedCount += 1;
        }
      });

      return appliedCount;
    },
    isVCRCategory() {
      return this.selectedEvent.isFalseEvent;
    },
    selectedDate() {
      return store.get('dashboard/dates');
    },
  },
  watch: {
    mediaTypeOptions(n) {
      this.filters.mediaTypeOptions = JSON.parse(JSON.stringify(n));
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    ioOptions(n) {
      if (n) {
        this.filters.ioOptions = JSON.parse(JSON.stringify(n));
      }
    },
    eventOptions(n) {
      this.filters.eventOptions = JSON.parse(JSON.stringify(n));
    },
    adGroupOptions(n) {
      this.filters.adGroupOptions = JSON.parse(JSON.stringify(n));
    },
    selectOneCampaignType(n) {
      const ioOptions = JSON.parse(JSON.stringify(this.filters.ioOptions));
      switch (n) {
        case WINDOW_TYPE_INCREMENTALITY:
        case WINDOW_TYPE_HALO_EFFECT:
          this.filters.ioOptions = ioOptions?.map((option) => {
            let tempOption = option;
            tempOption = {
              ...option,
              checked: false,
              disabled: !option.incrementalityEnabled,
            };
            return tempOption;
          });
          break;
        case WINDOW_TYPE_BRAND_LIFT:
          this.filters.ioOptions = ioOptions?.map((option) => {
            let tempOption = option;
            tempOption = {
              ...option,
              checked: false,
              disabled: !option.brandLiftEnabled,
            };
            return tempOption;
          });
          break;
        default:
          this.filters.ioOptions = ioOptions?.map((option) => ({ ...option, disabled: false }));
          break;
      }
    },
    selectedDate(newDate) {
      if (newDate) {
        this.$nextTick(() => {
          this.$store.set('dashboard/dates', newDate);
        });
      }
    },
  },
  created() {
    EventBus.$on('advance-filter-on-apply', this.onAdvanceFilterApply);
  },
  beforeDestroy() {
    // making sure that the sidepanel is closed
    this.$store.dispatch('common/setShowFilters', false);
    EventBus.$off('advance-filter-on-apply', this.onAdvanceFilterApply);
  },
  async mounted() {
    // check session storage for the user selected date
    const storedDate = sessionStorage.getItem('selectedDates');
    if (storedDate !== undefined && storedDate !== null) {
      this.$store.set('dashboard/dates', JSON.parse(storedDate));
    } else {
      let computedDate = await get('dashboard/dates');
      if (isDemoInstance()) {
        computedDate = { ...this.dates };
        this.$store.set('dashboard/dates', {
          ...computedDate,
          ...this.filters.dateRange,
        });
      } else {
        const { startDate, endDate, dateRangeOption, compareWith } = generateFlipDefaultDates();
        computedDate = { ...this.dates, startDate, endDate, dateRangeOption, compareWith };
        this.$store.set('dashboard/dates', {
          ...this.dates,
          ...computedDate,
        });
      }
    }

    this.$store.dispatch('dashboard/resetEventOptions');
    if (isDemoInstance()) {
      // check is cache already present then no need to bring fresh I0_name data
      const cachedFilters = getCachedFilters();
      if (!cachedFilters) {
        await this.fetchIos();
      }
    }
    if (this?.account?.id === MNI_ACCOUNT_ID || this?.account?.id === MNI_DEMO_ACCOUNT_ID) {
      this.eventLoadingForMni = true;
      this.mniAccount = true;
      this.$store.dispatch('common/setIsMniAccount', true);
      this.onSelectDayToConvert(this.daysToconvertOptions);

      await this.fetchIos();
      await this.fetchEvents();
      this.eventLoadingForMni = false;
    } else {
      this.mniAccount = false;
      this.$store.dispatch('common/setIsMniAccount', false);
    }
    // await this.fetchMediaTypes();

    if (this.isAdvanceFilterInOverview) {
      const initialFilterFromStorage = localStorage.getItem('initialFilters');
      if (initialFilterFromStorage === undefined || initialFilterFromStorage === null) {
        await this.fetchMediaTypes();
        await this.fetchIos();
        await this.fetchEvents();
        await this.fetchPublishers();
      }
    }

    const selectedIoOptions = this.ioOptions.filter((io) => io.checked);
    if (selectedIoOptions.length === 1) {
      await this.singleCampaignSettings(selectedIoOptions[0].campaignId, true);
    } else {
      this.defaultCampaignSettings(true);
    }
    this.filters = this.genFilters();

    if (this.isAdvanceFilterInOverview) {
      const initialFilterFromStorage = localStorage.getItem('initialFilters');
      if (initialFilterFromStorage !== undefined && initialFilterFromStorage !== null) {
        this.initialFilters = JSON.parse(initialFilterFromStorage);
      } else {
        this.initialFilters = JSON.parse(JSON.stringify(this.genFilters(true)));
        localStorage.setItem('initialFilters', JSON.stringify(this.initialFilters));
      }
    }

    this.applyFilters();
  },
  methods: {
    async campaignDropdownClosed() {
      if (this.filtersModified) {
        this.filters.mediaTypeOptions = await this.fetchSpecificMediaTypeOptions();
        this.filtersModified = false;
      }
    },
    async adGroupDropdownClosed() {
      if (this.filtersModified) {
        this.filters.mediaTypeOptions = await this.fetchSpecificMediaTypeOptions();
        this.filtersModified = false;
      }
    },
    onAdvanceFilterApply(applyFilters) {
      this.filters.ioOptions = applyFilters.ioOptions;
      this.filters.adGroupOptions = applyFilters.adGroupOptions;
      this.filters.mediaTypeOptions = applyFilters.mediaTypeOptions;
      this.filters.creativeOptions = applyFilters.creativeOptions;
      this.filters.audienceOptions = applyFilters.audienceOptions;
      this.filters.publisherOptions = applyFilters.publisherOptions;
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
    },
    setFilterOptions(name, values) {
      this.filtersModified = true;
      this.filters[name] = values;
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
    },
    async onSelectAdGroups(data) {
      this.filtersModified = true;
      this.filters.adGroupOptions = data;
    },
    toggleFilterModal() {
      this.showAdvanceFilters = !this.showAdvanceFilters;
      document.body.className += ' advance-filters';
    },
    toggleAdvanceFilterModal() {
      EventBus.$emit('show-advance-filter', this.filters);
    },
    onCloseAdvanceFilters() {
      if (!this.isAdvanceFilterInOverview) {
        this.showAdvanceFilters = false;
        document.body.className = document.body.className?.replace(' advance-filters', '');
      } else {
        this.filterOptionsArray.forEach((filter) => {
          this.setFilterOptions(filter, this.initialFilters[filter]);
        });
      }
    },
    toggleShowExportTable(val) {
      this.$store.dispatch('common/toggleShowExportTable', val);
      this.$store.dispatch('common/setSelectedExportTableRow', '');
    },
    onlyNumber(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      const inputValue = event.target.value;

      // Allow only numbers, backspace, and delete keys
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 46) {
        event.preventDefault();
      }

      // Limit input to 2 digits
      if (inputValue.length >= 2 && keyCode !== 8 && keyCode !== 46) {
        event.preventDefault();
      }
    },
    onSelectDayToConvert(data) {
      this.filtersModified = true;
      const daysToConvert = data.filter((item) => item.checked);
      this.filters.conversionWindow = daysToConvert[0].value * 24;
      this.$store.set('dashboard/filters@conversionWindow', this.filters.conversionWindow);
      this.$nextTick(() => {
        EventBus.$emit('do-report-search', true);
      });
    },
    genFilters(isInitialLoad = false) {
      if (!isInitialLoad) {
        const cachedFilters = getCachedFilters();
        if (cachedFilters) {
          return cachedFilters;
        }
      }

      const ioOptions = this.$store.copy('dashboard/filters@ioOptions');
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      const showUniques = this.$store.copy('dashboard/filters@showUniques');
      if (showUniques) {
        totalOrUniqueRadioOptions[1].active = true;
        totalOrUniqueRadioOptions[0].active = false;
      }
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      const methodology = this.$store.copy('dashboard/filters@methodology');
      methodologyOptions.forEach((mo) => {
        mo.active = methodology === mo.id;
      });
      return {
        closeTimeout: null,
        mediaTypeOptions: this.$store.copy('dashboard/filters@mediaTypeOptions'),
        audienceOptions: this.$store.copy('dashboard/filters@audienceOptions'),
        creativeOptions: this.$store.copy('dashboard/filters@creativeOptions'),
        publisherOptions: this.$store.copy('dashboard/filters@publisherOptions'),
        ioOptions,
        eventOptions: this.$store.copy('dashboard/filters@eventOptions'),
        attributionOptions: 'full',
        totalOrUniqueRadioOptions,
        conversionWindow: this.$store.copy('dashboard/filters@conversionWindow'),
        conversionWindowDisplay: this.$store.copy('dashboard/filters@conversionWindowDisplay'),
        conversionWindowUnit: this.$store.copy('dashboard/filters@conversionWindowUnit'),
        methodologyOptions,
        methodology,
        adGroupOptions: this.$store.copy('dashboard/filters@adGroupOptions'),
      };
    },
    defaultCampaignSettings(isMounted = false) {
      const totalOrUniqueOptions = this.$store
        .copy('dashboard/filters@totalOrUniqueRadioOptions')
        ?.map((attr, index) => {
          attr.active = index === 0;
          attr.disabled = false;
          return attr;
        });
      this.onSelectMethodology(DEFAULT_METHODOLOGY);
      this.updateConversionWindowUnit({
        name: DEFAULT_CONVERSION_WINDOW_UNIT,
      });
      this.filters.conversionWindowDisplay = DEFAULT_CONVERSION_WINDOW_DISPLAY;
      this.updateTotalOrUniqueRadioOptions(totalOrUniqueOptions);
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          DEFAULT_CONVERSION_WINDOW_DISPLAY
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (DEFAULT_CONVERSION_WINDOW_UNIT.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set('dashboard/filters@methodology', DEFAULT_METHODOLOGY);
        this.$store.set('dashboard/filters@showUniques', false);
      }
    },
    async onSelectIO(data) {
      this.filtersModified = true;
      this.isAdGroupLoading = true;
      let result = '';
      result = data.filter((f) => f?.checked);
      if (result?.length === 1 && !isBlank(result[0].campaignId) && result[0].campaignId > 0) {
        this.singleCampaignSettings(result[0].campaignId);
      } else {
        this.selectedCampaignGoal = {};
        this.defaultCampaignSettings();
      }
      let adGroupData = [];
      let response = '';
      if (result.length !== data.length) {
        const planIds =
          result
            ?.map((res) => {
              return res.campaignId;
            })
            ?.filter((fres) => fres)
            ?.join(',') || '';
        if (planIds) {
          response = await advertiserReportsAPI.adGroupOptions(
            this.advertiser ? this.advertiser.id : 0,
            buildQueryString({ planIds })
          );
          if (response?.data?.length > 0) {
            adGroupData = response?.data;
          }
        }
      } else {
        response = await advertiserReportsAPI.adGroupOptions(
          this.advertiser ? this.advertiser.id : 0
        );
        if (response?.data?.length > 0) {
          adGroupData = response?.data;
        }
      }
      if (adGroupData.length > 0) {
        adGroupData = adGroupData?.map((agd) => {
          return { ...agd, value: agd?.lineItemName || '', checked: true };
        });
      }
      this.filters.ioOptions = data;
      if (!this.isMniAccount) {
        // if MNI we dont need to set adgroup and hence no need  to show in filter selection count PS-1710 changes issue with filter count
        this.filters.adGroupOptions = adGroupData;
      }
      this.isAdGroupLoading = false;
    },
    updateTotalOrUniqueRadioOptions(payload) {
      this.filters.totalOrUniqueRadioOptions = payload;
    },
    updateConversionWindowUnit(val) {
      this.filters.conversionWindowUnit = val.name.toUpperCase();
    },
    onSelectMethodology(method) {
      this.filters.methodology = method;
    },
    applyModifiedFilters() {
      if (this.isMniAccount) {
        if (this.filtersModified) {
          this.applyFilters();
        }
      }
      this.onCloseAdvanceFilters();
    },

    openCloseFilters() {
      this.$store.dispatch('common/setShowFilters', !this.showFilters);
      this.$store.dispatch('common/setSelectOneCampaignType', '');

      this.filters = this.genFilters();
      if (this.isAdvanceFilterInOverview) {
        this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
      }
    },

    onEventDropdownClose(payload) {
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      if (!this.isAllEventsPerformanceSelected) {
        this.$store.set('dashboard/filters@eventOptions', JSON.parse(JSON.stringify(payload)));
      }
      const selectedEvent = buildProcessedEvent(payload);
      const toggleOptions = [...this.totalOrUniqueRadioOptions];

      if (!selectedEvent.isRevenueEvent) {
        toggleOptions[1].disabled = false;
      } else {
        if (this.totalOrUniqueRadioOptions[1].active) {
          toggleOptions[0].active = true;
          toggleOptions[1].active = false;
        }
        toggleOptions[1].disabled = true;
      }

      this.$store.set(
        'dashboard/filters@totalOrUniqueRadioOptions',
        JSON.parse(JSON.stringify(toggleOptions))
      );
      // this.$store.set('dashboard/filters@showUniques', false);
      this.$store.set(
        'dashboard/filters@showUniques',
        toggleOptions[1].active && !toggleOptions[1].disabled
      );
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setShowFilters', false);

      this.$nextTick(() => {
        this.filters.eventOptions = payload;
        setCacheFilters(JSON.stringify(this.filters));

        EventBus.$emit('do-report-search', true);
      });
    },

    async bDatepickerChanges(data) {
      this.$store.set(`common/RESET_CONVERSION_WINDOW`);
      const datePickerPayload = {
        startDate: convertEpochToNYTimezone(data.dates[0]),
        endDate: convertEpochToNYTimezone(data.dates[1]),
        compareWith: data.compareWith,
        compareStartDate: convertEpochToNYTimezone(data.dates[2]),
        compareEndDate: convertEpochToNYTimezone(data.dates[3]),
        dateRangeOption: data.dateRangeOption,
        dateCompareOption: data.dateCompareOption,
      };
      this.$store.set('dashboard/dates', datePickerPayload);
      sessionStorage.setItem('selectedDates', JSON.stringify(datePickerPayload));
      this.datePickerChangedGAEvent(datePickerPayload);
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });

      const selectedMediaTypes = getCachedFilters()?.mediaTypeOptions?.filter((mt) => mt.checked);
      await this.fetchMediaTypes(selectedMediaTypes, this.isAdvanceFilterInOverview);
      // await this.fetchIos();
      // await this.fetchEvents();

      // this.fireSettingsChangedGAEvent();
      // setCacheFilters(JSON.stringify(this.filters));
      // this.filters = this.genFilters();

      this.$forceUpdate();
      this.$nextTick(() => {
        setCacheFilters(JSON.stringify(this.filters));
        this.$store.dispatch('common/setShowFilters', false);
        EventBus.$emit('do-report-search', true);
        this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
      });
    },
    datePickerChangedGAEvent(datePickerPayload) {
      if (config.gaEnabled()) {
        const {
          startDate,
          endDate,
          compareWith,
          compareStartDate,
          compareEndDate,
          dateRangeOption,
          dateCompareOption,
        } = datePickerPayload;

        const gaEv = {
          start_date: startDate,
          end_date: endDate,
          compare: compareWith,
          date_range_option: DATEPICKER_RANGE_OPTIONS[`${dateRangeOption}`],
        };

        if (compareWith) {
          gaEv.compare_start_date = compareStartDate;
          gaEv.compare_end_date = compareEndDate;
          gaEv.compare_date_option = DATEPICKER_COMPARE_RANGE_OPTIONS[`${dateCompareOption}`];
        }
        this.fireGAEvent('flp_dtpk_change', gaEv);
      }
    },
    onSelectMediaTypes(data) {
      this.filtersModified = true;
      this.filters.mediaTypeOptions = data;
    },
    onSelecteDaysToConvert(data) {
      this.filters.conversionWindowDisplay = data.value;
    },
    async singleCampaignSettings(campaignId, isMounted = false) {
      this.loadingFilters = true;
      let flipCampaignInfo = {};
      if (campaignId && !this.isMniAccount) {
        const resPlan = await planApi.read(campaignId, {
          isLineItemsRequired: false,
        });
        flipCampaignInfo = resPlan.flipCampaignInfo || {};
      }
      // check  from where the campaign goals data is coming from
      this.selectedCampaignGoal = flipCampaignInfo?.goal ? flipCampaignInfo?.goal : {};
      const totalOrUniqueRadioOptions = this.$store.copy(
        'dashboard/filters@totalOrUniqueRadioOptions'
      );
      if (!flipCampaignInfo?.optimization?.impressions) {
        totalOrUniqueRadioOptions[1].active = false;
        totalOrUniqueRadioOptions[0].active = true;
      } else {
        totalOrUniqueRadioOptions.forEach((tuo) => {
          tuo.active =
            flipCampaignInfo?.optimization?.impressions?.value.toUpperCase() === tuo.text || false;
        });
      }
      this.updateTotalOrUniqueRadioOptions(totalOrUniqueRadioOptions);
      const conversionWindowObj = {
        name: flipCampaignInfo?.conversionWindow?.unit?.value || DEFAULT_CONVERSION_WINDOW_UNIT,
      };
      const methodologyOptions = this.$store.copy('dashboard/filters@methodologyOptions');
      let methodologyOption = {};
      this.filters.conversionWindowDisplay =
        flipCampaignInfo?.conversionWindow?.value || DEFAULT_CONVERSION_WINDOW_DISPLAY;
      methodologyOption = methodologyOptions.find((mo) => {
        return mo.name.toUpperCase() === flipCampaignInfo?.methodology?.value?.toUpperCase();
      });
      this.updateConversionWindowUnit(conversionWindowObj);
      this.onSelectMethodology(methodologyOption?.id || DEFAULT_METHODOLOGY);
      if (isMounted) {
        this.$store.set(
          'dashboard/filters@conversionWindowDisplay',
          this.filters.conversionWindowDisplay
        );
        this.$store.set(
          'dashboard/filters@conversionWindowUnit',
          conversionWindowObj.name.toUpperCase()
        );
        this.$store.set(
          'dashboard/filters@conversionWindow',
          this.filters.conversionWindowDisplay *
            (conversionWindowObj.name.toUpperCase() === 'DAYS' ? 24 : 1)
        );
        this.$store.set(
          'dashboard/filters@methodology',
          methodologyOption?.id || DEFAULT_METHODOLOGY
        );
        this.$store.set(
          'dashboard/filters@showUniques',
          totalOrUniqueRadioOptions[1].active && !totalOrUniqueRadioOptions[1].disabled
        );
      }
      this.loadingFilters = false;
    },
    applyFilters() {
      const prevIoOptions = this.$store.copy('dashboard/filters@ioOptions');
      this.$store.dispatch('common/setShowFilters', false);
      this.$store.dispatch('common/setSelectOneCampaignType', '');
      this.$store.dispatch('common/setIsWatchStats', {
        IMPRESSION: true,
        REACH: true,
        CONVERSION: true,
        LIFT: true,
      });
      this.$store.set('dashboard/filters@mediaTypeOptions', this.filters.mediaTypeOptions);
      this.$store.set('dashboard/filters@methodology', this.filters.methodology);
      this.$store.set('dashboard/filters@adGroupOptions', this.filters.adGroupOptions);
      this.$store.set(
        'dashboard/filters@ioOptions',
        this.filters.ioOptions?.map((option) => ({ ...option, disabled: false }))
      );

      if (!isEqual(prevIoOptions, this.filters.ioOptions)) {
        // const dateRange = getCampaignDateRange(this.filters.ioOptions, true);
        // this.filters.dateRange = dateRange;
        // this.$store.set('dashboard/dates', {
        //   ...this.dates,
        //   ...this.filters.dateRange,
        // });
        if (this.filters?.ioOptions?.filter((f) => f?.checked)?.length === 1) {
          this.changeEventOptions(prevIoOptions, true);
        } else {
          this.changeEventOptions(prevIoOptions, false);
        }
      }
      if (this.filters?.totalOrUniqueRadioOptions) {
        this.$store.set(
          'dashboard/filters@showUniques',
          this.filters?.totalOrUniqueRadioOptions[1].active &&
            !this.filters?.totalOrUniqueRadioOptions[1].disabled
        );
      }

      this.$store.set(
        'dashboard/filters@conversionWindowDisplay',
        this.filters.conversionWindowDisplay
      );
      this.$store.set(
        'dashboard/filters@conversionWindow',
        this.filters.conversionWindowDisplay *
          (this.filters.conversionWindowUnit === 'DAYS' ? 24 : 1)
      );
      this.$store.set('dashboard/filters@conversionWindowUnit', this.filters.conversionWindowUnit);
      this.$store.set('dashboard/filters@audienceOptions', this.filters.audienceOptions);
      this.$store.set('dashboard/filters@creativeOptions', this.filters.creativeOptions);
      this.$store.set('dashboard/filters@publisherOptions', this.filters.publisherOptions);
      this.fireSettingsChangedGAEvent();
      this.filtersModified = false;
      this.$store.dispatch('common/setAppliedFilterCount', this.appliedFilterCountNew);
      this.$nextTick(() => {
        setCacheFilters(JSON.stringify(this.filters));
        this.filters = this.genFilters();
        EventBus.$emit('do-report-search', true);
      });
    },
    fireSettingsChangedGAEvent() {
      if (config.gaEnabled()) {
        const gaEv = {
          media_type_options:
            this.filters.mediaTypeOptions.filter((o) => o.checked)?.map((o) => o.value) || [],
          methodology: this.filters.methodology,
          io_options:
            JSON.stringify(
              this.filters.ioOptions
                .filter((o) => o.checked)
                ?.map((o) => {
                  return {
                    value: o.value,
                  };
                })
            ) || [],
          attribution_options: 'full',
          show_uniques:
            this.filters.totalOrUniqueRadioOptions[1].active &&
            !this.filters.totalOrUniqueRadioOptions[1].disabled,
          conversion_window_display: this.filters.conversionWindowDisplay,
          conversion_window_unit: this.filters.conversionWindowUnit,
        };
        this.fireGAEvent('flp_sett_change', gaEv);
      }
    },
    changeEventOptions(prevIoOptions = [], isCheckForPopup = false) {
      const newEventOptions = JSON.parse(JSON.stringify(this.eventOptions));
      const subCategory = this.selectedCampaignGoal?.subCategories;
      const newSelectedOptions = newEventOptions?.map((ev) => {
        ev.selected = ev.category === this.selectedCampaignGoal?.category && ev.event !== 'Revenue';
        ev.open = ev.selected;
        const { children } = ev;
        children?.forEach((child) => {
          child.selected = subCategory?.includes(child.category);
        });
        return ev;
      });
      if (newSelectedOptions.filter((sev) => !sev.selected).length === this.eventOptions?.length) {
        const selectedIoOptions = this.ioOptions?.filter((io) => io.checked);

        if (!isBlank(selectedIoOptions)) {
          const ioOptionsWithGoal = selectedIoOptions.filter((opt) => opt?.goalCategory);
          const goalCategory = !isBlank(ioOptionsWithGoal)
            ? ioOptionsWithGoal[0]?.goalCategory
            : '';
          const selectedEvent = newSelectedOptions.find((ev) => ev.category === goalCategory);

          if (selectedEvent) {
            selectedEvent.selected = true;
          } else if (newSelectedOptions.length > 0) {
            newSelectedOptions[0].selected = true;
          }
        }
      }
      if (isCheckForPopup) {
        const prevOptionChecked = prevIoOptions.filter((opt) => opt.checked);
        const currentOptionsChecked = this.filters.ioOptions.filter((fopt) => fopt.checked);
        let restrictPopup = true;
        if (prevOptionChecked.length > 1 && currentOptionsChecked.length === 1) {
          restrictPopup = false;
        }
        if (
          prevOptionChecked.length <= 1 &&
          currentOptionsChecked.length <= 1 &&
          !(prevOptionChecked[0]?.value === currentOptionsChecked[0]?.value)
        ) {
          restrictPopup = false;
        }

        const selectedNewOption = newSelectedOptions.filter((nso) => nso.selected)?.[0] || {};
        const currentSelectedOption = this.eventOptions.filter((ept) => ept.selected)?.[0] || {};
        if (selectedNewOption.event !== currentSelectedOption.event && !restrictPopup) {
          this.showEventChangePopup = {
            visible: true,
            description: `Change Events Dropdown selection from ${currentSelectedOption.event} to ${selectedNewOption.event}?`,
            payload: {
              newSelectedOptions,
            },
          };
        }
        return;
      }
      this.$store.set('dashboard/filters@eventOptions', newSelectedOptions);
    },
    onClearAdvanceFilters() {
      if (!this.isAdvanceFilterInOverview) {
        this.filters = this.clearAdvanceFilters(this.filters);
      } else {
        this.filterOptionsArray.forEach((filter) => {
          this.setFilterOptions(filter, this.initialFilters[filter]);
        });
      }
    },
    onCloseEventChangePopup() {
      this.showEventChangePopup = { visible: false, payload: {} };
    },
    onChangeEventChangePopup() {
      const { payload } = this.showEventChangePopup;
      const { newSelectedOptions } = payload;
      this.$store.set('dashboard/filters@eventOptions', newSelectedOptions);
      this.showEventChangePopup = { visible: false, payload: {} };

      this.$nextTick(() => {
        this.filters.eventOptions = newSelectedOptions;
        setCacheFilters(JSON.stringify(this.filters));
      });
    },
    async validateAdvertiserSetup(n) {
      if (!n) {
        this.showIntermediateScreen = false;
        return;
      }
      if (!n?.selfServe) {
        this.showIntermediateScreen = false;
        return;
      }
      this.showIntermediateScreen = this.doesAdvertiserHaveNoCampaignsForReporting();
    },
    doesAdvertiserHaveNoCampaignsForReporting() {
      const hasNoCampaignForReporting = !(this.ioOptions && this.ioOptions.length > 0);
      if (hasNoCampaignForReporting) {
        this.warningMessage = 'Your campaign has to start before you can view reporting';
        this.warningActionButtonTitle = 'Manage Campaigns';
        this.warningActionButtonClick = this.openManageCampaigns;
        this.pageTitle = 'Reporting';
        this.emptyMessage = 'No campaigns have started yet.';
        this.pageActionButton1Title = 'Manage Campaigns';
        this.pageActionButton1Click = this.openManageCampaigns;
      }
      return hasNoCampaignForReporting;
    },
    openAccountSetup() {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.ACCOUNT_SETUP);
    },
    openManageCampaigns() {
      this.$store.dispatch('common/setIsWatchStats', ENUM_IS_WATCH_STATS);
      this.$store.dispatch('common/setSelectedMenuOptions', LAYOUT_TYPES.CAMPAIGNS);
    },
    onSelectAdvertiser() {
      this.filters.mediaTypeOptions = this.mediaTypeOptions;
      this.filters.ioOptions = this.ioOptions;
      this.filters.eventOptions = this.eventOptions;
    },
    truncateStr(str, n) {
      return str.length > n ? `${str.substr(0, n - 1)}...` : str;
    },
    onclickFilterSec() {
      if (this.filters.closeTimeout) {
        clearTimeout(this.filters.closeTimeout);
        this.filters.closeTimeout = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top-area-container {
  margin: 0px;
  padding-top: 20px;
}
@media screen {
  * {
    box-sizing: border-box;
  }
  * {
    outline: 0;
  }

  .light-theme {
    .top-export-icon {
      color: #444;
    }
  }
  .top-export-icon {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    margin-right: 30px;
    font-size: 21px;
    color: #ddd;
    vertical-align: top;
  }
  .top-export-icon.downloadIcon {
    margin: 12px 5px 0px 10px;
  }

  .top-export-icon:hover {
    color: var(--primarycolor);
  }
}

@media screen {
  * {
    box-sizing: border-box;
  }

  .wrap4icon.icon-cal {
    position: relative;
    display: inline-block;
  }
  * {
    outline: 0;
  }
  .intip4 {
    position: relative;
    top: 0;
  }
  .intip4[data-tool-tip]::after {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 99;
    display: block;
    width: 100px;
    padding: 10px 12px;
    margin-left: -50px;
    font-size: 12px;
    line-height: 1.2em;
    color: #5b5b5b;
    text-align: center;
    pointer-events: none;
    content: attr(data-tool-tip);
    background-color: #fdea85;
    border-radius: 3px;
    transition: ease 0.3s;
    transform: scale(0);
    transform-origin: bottom center;
  }
  .intip4[data-tool-tip]::after {
    width: 155px;
    margin-left: -70px;
    font-size: 12px;
    font-weight: 400;
    color: #222;
    pointer-events: none;
    background-color: rgba(245, 245, 250, 0.9);
  }
  .intip4[data-tool-tip]:hover::after {
    bottom: -145%;
    transform: scale(1);
  }
}

.expand-table-enter-active,
.expand-table-leave-active {
  -webkit-transition: left 0.3s ease, opacity 0.3s ease;
  -moz-transition: left 0.3s ease, opacity 0.3s ease;
  -ms-transition: left 0.3s ease, opacity 0.3s ease;
  -o-transition: left 0.3s ease, opacity 0.3s ease;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.expand-table-enter,
.expand-table-leave-to {
  transform: translateX(-100%) !important;
  opacity: 0 !important;
}
.filter-panel-wrap {
  align-items: center;
  margin-bottom: 0;
  color: #cad1d6;
  border-top: 1px solid #212429;
  transition: left 0.3s ease;
  .fp-text-link {
    font-size: 12px;
    color: #707e8a;
    display: inline-block;
    margin-top: 15px;
    display: flex;
    align-items: center;
    & p {
      text-decoration: underline;
      cursor: pointer;
    }
    & p:hover {
      color: var(--primarycolor) !important;
    }
    & span {
      text-decoration: none;
      margin-left: 8px;
      color: #cad1d6;
      .advance-filter-clear-icon:hover {
        color: var(--primarycolor) !important;
      }
    }
    & span:hover {
      color: #cad1d6;
    }
  }
}

.light-theme {
  .filter-panel-wrap {
    .filter {
      .filter-group {
        .flp-input {
          color: #212325;
          background-color: #fff;
          border: 1px solid #c5d2d8;
        }
      }
      ::v-deep .dropdown .dropdown-menu {
        li svg {
          color: #555 !important;
          &.check-off {
            color: transparent !important;
          }
        }
      }
    }
  }
}

::v-deep .filter-panel-wrap .filter.media-settings .dropdown .dropdown-menu {
  max-height: 300px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  li svg {
    font-size: 17px !important;
    box-shadow: inset 0px 0px 1px 1px #454546;
    color: #fff;
  }
}

.light-theme {
  .filter-panel-wrap {
    background-color: #fbfcfe;
    box-shadow: 10px 20px 20px rgb(63 66 77 / 16%);
    color: #212325;
    border-right: 1px solid #f2f6ff;
    border-top: unset;
    svg {
      color: #212325 !important;
    }
    .fp-text-link {
      p {
        color: #707e8a !important;
      }
      span {
        color: #212325 !important;
      }
    }
  }
}

.filter-panel-wrap {
  position: fixed;
  top: 55px;
  bottom: 0;
  // left: 20px;
  left: 0;
  z-index: 3;
  display: block;
  width: auto;
  min-width: 360px;
  height: 94vh;
  padding: 30px 80px 20px;
  background: #121215;
  background-color: rgb(16 16 19 / 93%);
  border-right: 1px solid #1d1f23;
  box-shadow: inset -10px 10px 20px rgb(0 0 0 / 30%);
  box-shadow: 10px 30px 30px rgb(0 0 0 / 50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  backdrop-filter: saturate(140%) blur(5px);
  span {
    margin-top: 2px;
  }
  svg {
    color: #cad1d6 !important;
  }
  .filter {
    ::v-deep .dropdown .dropdown-menu {
      overflow: unset !important;
      overflow-y: unset !important;
      li svg {
        font-size: inherit !important;
      }
      .intip4 {
        top: 2px !important;
        margin-left: 5px !important;
        &::after {
          bottom: 200% !important;
          width: 198px !important;
          margin-left: -73px !important;
          font-weight: 400 !important;
          text-align: left !important;
          letter-spacing: 0em !important;
        }
      }
    }
    .filter-ttl {
      margin-bottom: 10px;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.1em;
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    .filter-group > *:not(:first-child) {
      margin-top: 10px;
    }

    .filter-group {
      margin-bottom: 30px;
      .conv-content {
        position: relative;
        margin-top: 30px;
        // display: inline-block;

        .conv-ttl {
          position: absolute;
          top: -80%;
          font-size: 14px;
          color: #707e8a;
        }
      }

      .opt-b1 {
        display: inline-block;
        margin-right: 6px;
      }
      .opt-b2 {
        display: inline-block;
        width: 4.5rem;
        margin-left: 5px;
        vertical-align: bottom;
        & .checkbox-btn {
          height: unset;
        }
        ::v-deep .checkbox-btn {
          span {
            margin: 0 10px !important;
            font-size: 12px !important;
          }
        }
      }

      .input-conv {
        width: 60px;
      }

      .flp-input {
        padding: 10px;
        color: #ddd;
        background-color: transparent;
        border: 1px solid rgba(170, 170, 170, 0.2);
        border-radius: 4px;
      }
    }
  }
  .media-select {
    width: 180px !important;
    min-width: 180px !important;
  }
  .ad-group-select {
    width: 180px !important;
    min-width: 180px !important;
  }
  .close-filters {
    position: absolute;
    top: 10px;
    right: 0;
    margin-right: 20px;
    cursor: pointer;
    &:hover {
      color: var(--primarycolor) !important;
    }
  }
}

// Top Area CSS - START

.top-area {
  margin-bottom: 15px;
  color: #fff;
  width: 100%;
}
.logo {
  display: inline-block;
  width: 250px;
  height: auto;
}
.logo img {
  width: 100%;
  height: auto;
}
.top-area-center {
  position: relative;
  top: 0;
  left: -125px;
  display: flex;
  margin: 0;
}
.top-area-center-checkbox {
  position: relative;
  display: inline-block;
  margin-left: 40px;
}
label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input[type='checkbox'] {
  display: none;
}
input[type='checkbox'] + label:before {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:after {
  display: inline-block;
  font-family: 'FontAwesome';
}
input[type='checkbox'] + label:before {
  position: relative;
  top: -2px;
  left: 2px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  color: var(--primarycolor);
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 0;
}
input[type='checkbox']:checked + label:before {
  font-weight: 600;
  letter-spacing: 10px;
  content: '\f00c';
  opacity: 1;
}
input[type='checkbox'] + label::after {
  position: absolute;
  left: 0px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(150, 150, 150, 0.4);
  content: '\f1db';
}
input[type='checkbox']:checked + label::after {
  color: var(--primarycolor);
}
.top-area-right {
  position: inherit;
  display: inline-block;
  // float: right;
  // width: 972px;
  width: 100%;
  min-height: 50px;
  // overflow-x: scroll;
  // text-align: right;
  white-space: nowrap;
}
.input-value {
  position: relative;
  display: inline-block;
  width: 200px;
  min-width: 150px;
  height: 100%;
  padding: 10px 10px;
  font-size: 15px;
  text-align: left;
  border: 0px;
  border-radius: 4px;
}
.icon-cal {
  position: relative;
  top: -6px;
  display: inline-flex;
  float: right;
  vertical-align: top;
  right: -18px;
  align-self: flex-end;
}
.light-theme {
  .icon-cal {
    right: -28px;
  }
  .sideopen {
    .icon-cal {
      right: 122px;
    }
  }
}
.sideopen {
  .icon-cal {
    right: 130px !important;
  }
}
::v-deep .icon-cal .wrap svg {
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 1;
  margin-top: -11px;
  pointer-events: none;
}
.intdark {
  color: #fff;
  background-color: #262933;
}
// Top Area CSS - END

// Last Area CSS - START

.nav-tabs {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  display: inline-block;
  margin: -33px 0px 0px 0;
}
.light-theme .left-part {
  background-color: #fff;
  color: #212325;
  box-shadow: 0px 14px 35px -12px rgba(0, 40, 100, 0.17);
  border: 1px solid rgb(165 173 178 / 22%);
  border-radius: 8px;
}

.left-part {
  position: relative;
  z-index: 2;
  float: left;
  width: 30%;
  min-width: 366px;
  min-height: 585px;
  padding: 30px 0px;
  color: #fff;
  background-color: #212429;
  border-radius: 0px;
}

// Download Icon CSS - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-12 {
  width: 0.75em;
}

.fa-arrow-to-bottom:before {
  content: '\f33d';
}

// Download Icon CSS - END

// Export Table - START

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  vertical-align: -0.125em;
}
.svg-inline--fa.fa-w-10 {
  width: 0.625em;
}

.fa-times:before {
  content: '\f00d';
}

.fa-times:before {
  content: '\f00d';
}
.top-last {
  margin-top: 0px;
}
::v-deep .dropdown {
  margin-right: 0 !important;
}

::v-deep .form-input-field {
  background-color: #262933;
  border: none;
  border-radius: 4px;
}
::v-deep .form-input-field.v-select .vs__dropdown-toggle .vs__actions .action-icons svg {
  color: inherit !important;
}
::v-deep .form-input-wrap .form-click .action-icons {
  padding: 0 11px 0 0 !important;
}
::v-deep .form-input-wrap .form-click {
  min-height: 40px;
}
::v-deep .form-input.form-date svg {
  left: 90%;
  z-index: 1;
}

.dboard-select {
  width: 12rem;
  min-width: 182px;
  margin-top: 4px;
  margin-right: 1.2rem;
  margin-left: 0;
  background-color: #474a56;
  border-radius: 4px;
}
::v-deep .icon-cal .form-input-field {
  width: 190px;
  margin: 4px 0 0 0;
  cursor: pointer;
}
::v-deep .k-btn {
  // display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: var(--buttontext) !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--primarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 22px;
  line-height: 20px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}

.no-display {
  display: none !important;
}

.filter-cnt-bubble {
  background-color: var(--primaryalt);
}
.check-box-row {
  display: flex;
}
.check-box-row > div {
  padding: 0 4px; /* Adjust padding as needed */
}
.check-box-row > div:last-child {
  margin-left: auto;
}
.mni-custom-dd {
  color: black;
  min-width: 200px !important;
  min-height: 40px;
  border-radius: 4px;
}
.filter-cnt-badge {
  white-space: nowrap;
  text-align: center;
  line-height: 18px;
  padding: 0 6px;
  height: 18px;
  background: var(--primarycolor);
  position: absolute;
  border-radius: 50%;
  font-size: 12px;
  color: var(--buttontext);
  left: 13px;
  top: -5px;
  font-weight: 600;
}

// .opt-b1 {
//   display: flex;
//   align-items: center;
//   border-radius: 4px;
//   background-color: #ffffff;
//   border: 1px solid #c5d2d8;
//   height: 40px;
//   margin-top: 2px !important;
//   min-width: 140px !important;
//   margin-left: 4px;
// }
// .opt-b1 {
//   min-width: 198px !important;
//   height: 40px;
//   border-radius: 4px;
//   position: relative;
//   border: 1px solid #c5d2d8;
//   background-color: #ffffff;
//   align-items: center;
//   margin-top: 2px !important;
//   margin-left: 6px;
// }
// .flp-input {
//   padding-left: 6px;
//   font-size: 14px;
//   max-width: 30px;
//   top: 15px;
//   left: 4px;
//   background: transparent !important;
//   border: transparent !important;
// }
.input-conv {
}
.events-dd {
  padding-top: 2px;
  max-width: 200px !important;
}
.cnv-text {
  font-size: 14px;
  color: #969caf;
  padding-left: 5px;
  margin-top: -2px;
}
.ftop-icons {
  position: relative;

  display: inline-block;
  vertical-align: top;
  color: #536176;
  cursor: pointer;
  position: relative;
  font-size: 17px;
  margin-right: 3px;
  margin-left: 4px;
  position: relative;
  align-self: center;

  .ttip-wrap {
    position: absolute;
    width: max-content;
    text-align: center;
    transform: translate3d(0px, 0px, 0px);
    padding-top: 0px;
    display: inline-block;
    visibility: hidden;
    opacity: 0;
    font-size: 14px;
    transition: all 0.2s ease;
    z-index: 103;
    pointer-events: none;
    left: -47px;
    top: 20px;
    .ttip {
      display: inline-block;
      position: relative;
      padding: 4px 8px;
      border-radius: 6px;
      background-color: var(--primarydark2);
      color: #fff;
      text-align: center;
      font-size: 0.8em;
      font-weight: 500;
      line-height: 1.1em;
      margin-top: 0px;
    }
    .ttip::before {
      content: '';
      display: inline-block;
      left: 50%;
      top: -5px;
      height: 0px;
      width: 0px;
      border-right: solid 6px transparent;
      border-left: solid 6px transparent;
      border-bottom: solid 6px var(--primarydark2);
      position: absolute;
      margin-left: -3px;
    }
  }
  &:hover .fa-filter {
    color: var(--primarycolor);
  }
  &:hover .ttip-wrap {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0px, 6px, 0px);
  }
}
.applied-cnt {
  position: absolute;
  background: var(--primaryalt);
  z-index: 2;
  display: inline-block;
  color: #fff;
  font-size: 10px;
  font-weight: 800;
  padding: 1px 4px 1px 4px;
  line-height: 1.3em;
  border-radius: 20px;
  text-align: center;
  margin: -6px 0px 0px -8px;
  top: 0px;
  right: -4px;
}
</style>
